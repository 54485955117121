import React from 'react';
import { Sidebar, SketchNavigation } from '../components/Sidebar';
import { SketchGenerator } from '../sketches/generators/SketchGenerator';
import { GeneralSketchProps } from '../sketches/types';
import styles from './sketches.module.css';

interface Props {
    sketchId: string;
    sketch: ((args: GeneralSketchProps) => React.ReactElement<GeneralSketchProps>)
    height: number
    width: number
    shouldRefresh?: boolean
    refreshTimer?: number
}

export const SketchTemplate = ({ sketch, sketchId, height, width, shouldRefresh, refreshTimer }: Props) => {
    return <div className={styles.container}>
        <div className={styles.sketchContainer}>
            <div className={styles.navigation}>
                <SketchNavigation name={sketchId}/>
            </div>
            <div className={styles.sidebar}>
                <Sidebar name={sketchId} />
            </div>
            <div className={styles.sketchWrapper}>
                <SketchGenerator sketchId={sketchId} MySketch={sketch} height={height} width={width} shouldRefresh={shouldRefresh} refreshTimer={refreshTimer} />
            </div>
        </div>
    </div>
}