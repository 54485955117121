import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { GeneralSketchProps } from '../types';

export function Pipes({ height, width }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {

    const nDotsPerRow = 10
    const initialDifference = width / nDotsPerRow

    let dotPositions: DotLine[] = []
    const setup = (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(width, height).parent(canvasParentRef);
        p5.background('#1A202C')
        for (let x = 0; x < nDotsPerRow; x++) {
            for (let y = 0; y < nDotsPerRow; y++) {
                dotPositions.push(
                    DotLine.from(
                        p5,
                        x * initialDifference + initialDifference / 2,
                        y * initialDifference + initialDifference / 2,
                        initialDifference
                    )
                )
            }
        }
    };

    let angle = 0.0;

    const maxIter = 6
    let currentIter = 0

    const draw = (p5: p5Types) => {
        p5.fill(255, 0, 0)
        dotPositions.forEach(p => {
            p.showDot(p5)
            p.animateLine(p5, angle)
            p.showLine(p5)
        })
        angle += 2
        if (angle === 100 && currentIter < maxIter) {
            dotPositions = [...dotPositions, ...dotPositions.map(p => p.createChild(p5))]
            angle = 0.0
            currentIter += 1
        }
    };
    return <Sketch setup={setup} draw={draw} />
}


class DotLine {
    start: p5Types.Vector
    end: p5Types.Vector;
    current: p5Types.Vector;
    r: number;
    g: number;
    b: number;
    lengthBetweenPoints: number
    completed: boolean;

    constructor(p5: p5Types, x: number, y: number, lengthBetweenPoints: number, r: number, g: number, b: number) {
        this.start = p5.createVector(x, y);
        const x2 = p5.random([x + lengthBetweenPoints / 2, x - lengthBetweenPoints / 2])
        const y2 = p5.random([y + lengthBetweenPoints / 2, y - lengthBetweenPoints / 2])


        this.end = p5.createVector(x2, y2);
        this.current = this.end;
        this.r = r;
        this.g = g;
        this.b = b;
        this.lengthBetweenPoints = lengthBetweenPoints;
        this.completed = false;
    }

    static from(p5: p5Types, x: number, y: number, lengthBetweenPoints: number): DotLine {
        return new DotLine(p5, x, y, lengthBetweenPoints, p5.random(255), p5.random(255), p5.random(255))
    }

    showDot(p5: p5Types) {
        if (!this.completed) {
            p5.noStroke()
            p5.circle(this.start.x, this.start.y, 1)
        }
    }

    showLine(p5: p5Types) {
        if (!this.completed) {
            p5.stroke(this.r, this.g, this.b);
            p5.line(this.start.x, this.start.y, this.current.x, this.current.y)
        }
    }

    animateLine(p5: p5Types, angle: number) {
        const tempX = p5.map(angle, 0, 100, this.start.x, this.end.x, true);
        const tempY = p5.map(angle, 0, 100, this.start.y, this.end.y, true);

        this.current = p5.createVector(tempX, tempY);
    }

    createChild(p5: p5Types): DotLine {
        this.completed = true;
        return new DotLine(p5, this.end.x, this.end.y, this.lengthBetweenPoints - 10, this.r, this.g, this.b)
    }
}