import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { GeneralSketchProps } from '../types';


export function Trail({ height, width }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {


    let trails: SingleTrail[] = [];

    const setup = (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(width, height).parent(canvasParentRef);
        p5.angleMode(p5.DEGREES)



        trails = [new SingleTrail(p5,
            p5.createVector(
                p5.random(width / 2, width / 2 + p5.random(0, 100) - 50),
                p5.random(width / 2, width / 2 + p5.random(0, 100) - 50)),
            p5.createVector(p5.random(0, width),
                p5.random(0, height)))]


    };

    let tick = 0

    let spawnIndex = 0

    let endTick = 80


    const draw = (p5: p5Types) => {
        p5.background('#1A202C')
        p5.noStroke()

        trails.forEach(t => t.draw(p5))

        tick++

        if (tick % 10 === 0 && tick < endTick) {
            const currentIndex = trails.length
            trails = [...trails, ...trails.slice(spawnIndex).map(t => t.createSpawn(p5, p5.createVector(p5.random(0, height), p5.random(0, width))))]
            spawnIndex = currentIndex
        }


    }


    return <Sketch setup={setup} draw={draw} />
}



class SingleTrail {
    points: p5Types.Vector[];
    r: number;
    g: number;
    b: number;
    startFrame: number;
    finished: boolean;
    currentI: number;


    constructor(p5: p5Types, start: p5Types.Vector, end: p5Types.Vector) {
        this.points = []

        const nBezierPoints = p5.int(p5.random(60, 130))
        const mid1 = p5.createVector(p5.random(0, 400), p5.random(0, 400))
        const mid2 = p5.createVector(p5.random(0, 400), p5.random(0, 400))
        for (let i = 0; i <= 1; i += 1 / nBezierPoints) {
            const x = p5.bezierPoint(start.x, mid1.x, mid2.x, end.x, i)
            const y = p5.bezierPoint(start.y, mid1.y, mid2.y, end.y, i)
            const r = p5.random(25)
            this.points.push(p5.createVector(x, y, r))
        }

        this.r = p5.random(100, 255)
        this.g = p5.random(100, 255)
        this.b = p5.random(100, 255)
        this.startFrame = p5.frameCount;
        this.currentI = 0;
        this.finished = false;

    }

    draw(p5: p5Types) {
        this.currentI++
        p5.fill(this.r, this.g, this.b, 200)
        this.points.slice(0, p5.int(this.currentI)).forEach(
            p => {
                const currentRadius = (p.z - (p5.frameCount - this.startFrame) / 10)
                if (currentRadius > 0) {
                    p5.circle(p.x, p.y, currentRadius)
                }
            }
        )
    }

    createSpawn(p5: p5Types, end: p5Types.Vector): SingleTrail {
        const start = p5.random(this.points)
        return new SingleTrail(p5, start, end)
    }

}