import React from 'react';
import styles from './progress.module.css';

interface Props {
    timer: number;
    maxTime: number;
}

export const ProgressLoader = ({timer, maxTime}: Props) => {

    const style = { 
        "--timer": (timer / maxTime) * 100 + '%',
     } as React.CSSProperties;

     return <div style={style} className={styles.progressBar}/>

}