import { useEffect, useState } from "react";

const sizeSm = 600

export const useViewport = () => {
    const [height, setHeight] = useState(window.innerHeight);
    const [sm, setSm] = useState<boolean>(window.innerWidth < sizeSm);
    const [width, setWidth] = useState(window.innerWidth);
        
    useEffect(() => {
       const handleWindowResize = () => {
          setHeight(window.innerHeight);
          setSm(window.innerWidth < 600);
          setWidth(window.innerWidth);
       };
       window.addEventListener('resize', handleWindowResize);
       return () => window.removeEventListener('resize', handleWindowResize);
    }, []);
    
    return {
       height,
       sm,
       width,
    };
 };