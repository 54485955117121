import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { GeneralSketchProps } from '../types';


export function RainbowRoad({ height, width }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {


    let roads: SingleRoad[] = [];
    let endTick: number;

    const setup = (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(width, height).parent(canvasParentRef);
        p5.angleMode(p5.DEGREES)
        p5.colorMode(p5.HSB)
        p5.background('#1A202C')
        endTick = p5.int(p5.random(40,100))


        roads = [new SingleRoad(p5,
            p5.createVector(
                p5.random(width / 2, width / 2 + p5.random(0, 100) - 50),
                p5.random(width / 2, width / 2 + p5.random(0, 100) - 50)),
            p5.createVector(p5.random(0, width),
                p5.random(0, height)))]


    };

    let tick = 0

    let spawnIndex = 0



    const draw = (p5: p5Types) => {
        p5.noStroke()

        roads.forEach(t => t.draw(p5))

        tick++

        if (tick % 10 === 0 && tick < endTick) {
            const currentIndex = roads.length
            roads = [...roads, ...roads.slice(spawnIndex).map(t => t.createSpawn(p5, p5.createVector(p5.random(0, height), p5.random(0, width))))]
            spawnIndex = currentIndex
        }


    }


    return <Sketch setup={setup} draw={draw} />
}

enum StrokeParams {
    OFFSET_X_ONLY = "OFFSET_X_ONLY",
    OFFSET_Y_ONLY = "OFFSET_Y_ONLY",
    OFFSET_X_AHEAD = "OFFSET_X_AHEAD",
    OFFSET_X_TRAILING = "OFFSET_X_TRAILING",
    OFFSET_Y_TRAILING = "OFFSET_Y_TRAILING",
    OFFSET_Y_AHEAD = "OFFSET_Y_AHEAD"
}

class SingleRoad {
    centerLinePoints: p5Types.Vector[];
    nStrokes: number;
    strokeParam: StrokeParams;
    h: number;
    s: number;
    b: number;
    startFrame: number;
    finished: boolean;
    currentI: number;


    constructor(p5: p5Types, start: p5Types.Vector, end: p5Types.Vector) {
        this.centerLinePoints = []

        this.nStrokes = p5.random(5,10)

        this.strokeParam = p5.random(Object.values(StrokeParams))

        const nBezierPoints = 600
        const mid1 = p5.createVector(p5.random(0, 400), p5.random(0, 400))
        const mid2 = p5.createVector(p5.random(0, 400), p5.random(0, 400))
        for (let i = 0; i <= 1; i += 1 / nBezierPoints) {
            const x = p5.bezierPoint(start.x, mid1.x, mid2.x, end.x, i)
            const y = p5.bezierPoint(start.y, mid1.y, mid2.y, end.y, i)
            const r = p5.random(25)
            this.centerLinePoints.push(p5.createVector(x, y, r))
        }

        this.h = p5.random(10, 255)
        this.s = 255
        this.b = 255
        this.startFrame = p5.frameCount;
        this.currentI = 0;
        this.finished = false;

    }

    draw(p5: p5Types) {
        const indexDelta = this.currentI < 4 ? 1 : 20 
        p5.fill(this.h, this.s, this.b)
        for(let pDelta = -25; pDelta < 26; pDelta+=5) {
            this.centerLinePoints.slice(p5.int(this.currentI), p5.int(this.currentI) + indexDelta).forEach(
                p => {
                    switch(this.strokeParam) {
                        case StrokeParams.OFFSET_X_ONLY:
                            p5.circle(p.x + pDelta,p.y, 1)
                        break;
                        case StrokeParams.OFFSET_Y_ONLY:
                            p5.circle(p.x,p.y + pDelta, 1)
                        break;
                        
                        case StrokeParams.OFFSET_X_AHEAD:
                            p5.circle(p.x + pDelta, p.y + pDelta / 5, 1)                        
                        break;
                        case StrokeParams.OFFSET_X_TRAILING:
                            p5.circle(p.x + pDelta, p.y - pDelta / 5, 1)
                        
                        break;
                        case StrokeParams.OFFSET_Y_AHEAD:
                            p5.circle(p.x + pDelta / 5,p.y + pDelta, 1)
                        break;
                        case StrokeParams.OFFSET_Y_TRAILING:
                            p5.circle(p.x + pDelta / 5,p.y - pDelta, 1)
                        break;

                    }
                }
            )
        }
        this.currentI++
    }

    createSpawn(p5: p5Types, end: p5Types.Vector): SingleRoad {
        const start = p5.random(this.centerLinePoints)
        return new SingleRoad(p5, start, end)
    }

}