import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { makeGrid } from '../utils';
import { GeneralSketchProps } from '../types';
import { MyCompletedSketches } from '../../App';

enum Direction {
    UP,
    DOWN,
    RIGHT,
    LEFT
}

interface TileShade {
    right?: {active: boolean, shadowDirection: Direction};
    bot?: {active: boolean, shadowDirection: Direction};
    top?: {active: boolean, shadowDirection: Direction};
    left?: {active: boolean, shadowDirection: Direction};
}

type WeaveIntersection = {
    first: TileShade;
    second: TileShade;
}

export function Tapestry({height, width}: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {

    const layerableSketches = MyCompletedSketches.filter(it => !it.ignoredFromLayering)


    let SecondSketch = layerableSketches?.[getRandomInt(0, layerableSketches.length - 1)]?.element

    let nWeaves = 16
    let nTiles = nWeaves * 2 - 1
    
    const tileHeight = height / nTiles

    let shades: WeaveIntersection[][] = makeGrid(nTiles, (x,y) => {

        const firstTimeGrid = x % 2 === 0 && y % 2 === 0

        const secondTimeGrid = x % 2 !== 0 && y % 2 !== 0
        
        const secondTimeRightLeft = secondTimeGrid && (((x + 1) % 4 !== 0 && (y + 1) % 4 !== 0) || ((x + 3) % 4 !== 0 && (y + 3) % 4 !== 0))

        const secondTimeTopBot = secondTimeGrid && !secondTimeRightLeft

        return {
            first: { 
                bot: {
                    active: firstTimeGrid && y !== nTiles -1,
                    shadowDirection: Direction.DOWN
                },
                top: {
                    active: firstTimeGrid && y !== 0,
                    shadowDirection: Direction.UP
                },
                right: {
                    active: firstTimeGrid && x !== nTiles - 1,
                    shadowDirection: Direction.RIGHT
                },
                left: {
                    active: firstTimeGrid && x !== 0,
                    shadowDirection: Direction.LEFT
                }
            },
            second: { 
                bot: {
                    active: secondTimeTopBot,
                    shadowDirection: Direction.DOWN
                },
                top: {
                    active: secondTimeTopBot,
                    shadowDirection: Direction.UP
                },
                right: {
                    active: secondTimeRightLeft,
                    shadowDirection: Direction.RIGHT
                },
                left: {
                    active: secondTimeRightLeft,
                    shadowDirection: Direction.LEFT}
                }
        }
    })

    const setup = (p5: p5Types, canvasParentRef: Element) => {
          p5.createCanvas(width, height).parent(canvasParentRef);
          p5.angleMode(p5.DEGREES)
          p5.noiseDetail(1, 0)

        };

  
      const draw = (p5: p5Types) => {
            p5.noLoop()
            p5.stroke(0)
            const c1 = p5.color(0)
            const c2 = p5.color(60,0)
            
            shades.forEach(row => {
                row.forEach(tile => {
                    if(tile.first?.bot?.active) {
                        setGradient(p5,0,tileHeight - 4, tileHeight, 4,c2,c1, Direction.DOWN )
                    }
                    if(tile.first?.right?.active) {
                        setGradient(p5,tileHeight - 4,0, 4, tileHeight,c2,c1, Direction.LEFT )
                    }
                    if(tile.first?.top?.active) {
                        setGradient(p5,0,0, tileHeight, 4,c1,c2, Direction.DOWN )
                    }
                    if(tile.first?.left?.active) {
                        setGradient(p5,0,0,4, tileHeight,c1,c2, Direction.LEFT )
                    }

                    p5.translate(tileHeight, 0)
                })
                p5.translate(-width, tileHeight)
            });
            p5.translate(0, -tileHeight * nTiles)
            

           const shadow2Height = 6
            shades.forEach(row => {
                row.forEach(tile => {
            
                    if(tile.second?.bot?.active) {
                        setGradient(p5,0,tileHeight, tileHeight, shadow2Height,c1,c2, Direction.DOWN )
                    }
                    if(tile.second?.right?.active) {
                        setGradient(p5,tileHeight,0, shadow2Height, tileHeight,c1,c2, Direction.LEFT )
                    }
                    if(tile.second?.top?.active) {
                        setGradient(p5,0,-shadow2Height, tileHeight, shadow2Height,c2,c1, Direction.DOWN )
                    }
                    if(tile.second?.left?.active) {
                        setGradient(p5,-shadow2Height,0,shadow2Height, tileHeight,c2,c1, Direction.LEFT )
                    }
                    

                    p5.translate(tileHeight, 0)
                })
                p5.translate(-width, tileHeight)
            });
            p5.translate(0, -tileHeight * nTiles)


      };
    return <div style={{position: "relative"}}>
        <Sketch setup={setup} draw={draw} />
        <div style={{height, width, position: "absolute", top:0, zIndex: -1}}>
            {SecondSketch && <SecondSketch height={height} width={width} circular={false} />}
        </div>
    </div>
  }

  function setGradient(p5: p5Types, x: number, y: number, w: number, h: number, c1: p5Types.Color, c2: p5Types.Color, direction: Direction) {
    p5.noFill();
  
    if (direction === Direction.DOWN) {
      // Top to bottom gradient
      for (let i = y; i <= y + h; i++) {
        let inter = p5.map(i, y, y + h, 0, 1);
        let c = p5.lerpColor(c1, c2, inter);
        p5.stroke(c);
        p5.line(x, i, x + w, i);
      }
    } else if (direction === Direction.LEFT) {
      // Left to right gradient
      for (let i = x; i <= x + w; i++) {
        let inter = p5.map(i, x, x + w, 0, 1);
        let c = p5.lerpColor(c1, c2, inter);
        p5.stroke(c);
        p5.line(i, y, i, y + h);
      }
    }
    p5.stroke(0)
  }

  function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}