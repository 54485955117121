import React from 'react';
import { useColorMode, Switch, Stack } from '@chakra-ui/react'
import { FaSun, FaMoon } from 'react-icons/fa'

export function Colormode() {
    const { colorMode, toggleColorMode } = useColorMode()
    return (
        <header>
            <Stack direction='row'>
                <Switch onChange={toggleColorMode} />
                {colorMode === 'light' ? <FaMoon /> : <FaSun />}
            </Stack>
        </header>
    )
}