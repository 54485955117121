import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { makeGrid } from '../utils';
import { GeneralSketchProps } from '../types';

interface Color {
    red: number;
    green: number;
    blue: number;
}

interface Tile {
    red: number;
    green: number;
    blue: number;
}

export function TileMapTriangles({ height, width }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {

    const nTilesInRow = 10
    const nColors = 2

    const tileWidth = width / nTilesInRow
    const tileHeight = height / nTilesInRow

    let colors: Color[] = []

    let tiles: Tile[][] = [[]]

    const setup = (p5: p5Types, canvasParentRef: Element) => {
        colors = Array(nColors).fill(0).map(_ =>
        ({
            red: p5.random(255),
            green: p5.random(255),
            blue: p5.random(255)
        })
        )
        p5.createCanvas(width, height).parent(canvasParentRef);
        tiles = makeGrid(nTilesInRow, () => {
            const r = Math.floor(p5.map(p5.random(), 0, 1, 0, colors.length))
            const c = colors[r]
            return ({
                red: c.red,
                green: c.green,
                blue: c.blue
            }
            )
        }
        )
        p5.angleMode(p5.DEGREES)
        p5.background('#1A202C')

    };

    const draw = (p5: p5Types) => {
        p5.noStroke()
        p5.blendMode(p5.EXCLUSION)
        tiles.forEach((row, x) => row.forEach((tile, y) => {
            const startX = x * tileWidth
            const startY = y * tileHeight
            p5.fill(tile.red, tile.green, tile.blue)
            p5.rect(startX, startY, tileWidth, tileHeight)
            p5.fill(tile.blue, tile.red, tile.green)

            const centerX = (x + 0.5) * tileWidth
            const centerY = (y + 0.5) * tileHeight

            const topRight = p5.createVector(tileWidth / 2, -tileHeight / 2)
            const topLeft = p5.createVector(-tileWidth / 2, -tileHeight / 2)
            const botLeft = p5.createVector(-tileWidth / 2, tileHeight / 2)
            const botRight = p5.createVector(tileWidth / 2, tileHeight / 2)


            const showTriangle = p5.random() < 0.5
            const showArc = p5.random() < 0.5

            const cornerTriangle = p5.map(p5.random(), 0, 1, 0, 2)
            const cornerArc = p5.map(p5.random(), 0, 1, 0, 4)

            const r = Math.floor(p5.map(p5.random(), 0, 1, 0, colors.length))
            const c = colors[r]

            p5.fill(c.red, c.green, c.blue)
            p5.push()
            p5.translate(centerX, centerY)
            if (showTriangle) {
                if (cornerTriangle < 1) {
                    createTriangle(p5, topLeft, botLeft, topRight)
                }
                else if (cornerTriangle < 2) {
                    createTriangle(p5, topLeft, topRight, botRight)
                }
            }
            if (showArc) {
                if (cornerArc < 1) {
                    p5.arc(botLeft.x, botLeft.y, 2 * tileWidth, 2 * tileHeight, 270, 0)
                }
                else if (cornerArc < 2) {
                    p5.arc(botRight.x, botRight.y, 2 * tileWidth, 2 * tileHeight, 180, 270)

                }
                else if (cornerArc < 3) {
                    p5.arc(topLeft.x, topLeft.y, 2 * tileWidth, 2 * tileHeight, 0, 90)
                }
                else if (cornerArc < 4) {
                    p5.arc(topRight.x, topRight.y, 2 * tileWidth, 2 * tileHeight, 90, 180)
                }
            }
            p5.translate(-centerX, -centerY)
            p5.pop()


            p5.noLoop()
        })

        )

    };
    return <Sketch setup={setup} draw={draw} />
}

const createTriangle = (p5: p5Types, p1: p5Types.Vector, p2: p5Types.Vector, p3: p5Types.Vector) =>
    p5.triangle(p1.x, p1.y, p2.x, p2.y, p3.x, p3.y)