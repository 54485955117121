import React, { useEffect, useState } from 'react';
import { ProgressLoader } from '../components/ProgressLoader';
import { useInterval } from '../useInterval';


const rgbToHex = (r: number, g: number, b: number): string => '#' + [r, g, b].map(x => {
  const hex = x.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}).join('')

interface Props {
  height: number;
  width: number;
  background: number | string;
  sketchId: string
  refreshTimer?: number
  triggerRefresh: () => void
}

const delta = 10

export function SketchStacker({ height, width, sketchId, background, triggerRefresh, refreshTimer = 5000 }: Props) {

  const [timer, setTimer] = useState(0)

  useEffect(() => {
    setTimer(0)
  }, [sketchId])

  useEffect(() => {
    if ((timer % refreshTimer) === 0 && timer !== 0) {
      triggerRefresh()
    }
  }, [refreshTimer, timer, triggerRefresh])
  useInterval(() => setTimer(timer + delta), delta)

  const drawings = document.getElementsByTagName("canvas")

  const saveToFile = () => {
    const offsetH = height * 0.1
    const offsetW = width * 0.1
    const cv = document.createElement("canvas")
    cv.setAttribute("height", (height + offsetH) * 2 + "")
    cv.setAttribute("width", (width + offsetW) * 2 + "")
    const ctx = cv.getContext("2d")
    if (ctx) {
      ctx.fillStyle = typeof background === "string" ? background : rgbToHex(background, background, background)
      ctx.fillRect(0, 0, (width + offsetW) * 2, (height + offsetH) * 2)
      for (let x = 0; x < drawings.length; x += 1) {
        ctx?.drawImage(drawings[x], offsetW, offsetW)
      }
    }

    const file = cv.toDataURL("image/png")
    var newTab = window.open('about:blank', 'image from canvas');
    newTab?.document.write("<img src='" + file + "' alt='from canvas' />");
  }



  return <>
    <ProgressLoader timer={timer % refreshTimer} maxTime={refreshTimer} />
  </>

}
