import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { GeneralSketchProps } from '../types';

export function FlowFieldSketch({ height, width, circular = true }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {


  const lines: FlowLine[] = []

  const circleRadius = (height + width) / 4


  const setup = (p5: p5Types, canvasParentRef: Element) => {
    p5.createCanvas(width, height).parent(canvasParentRef);
    p5.angleMode(p5.DEGREES)
    p5.noiseDetail(1, 0)
    if (circular) {
      p5.noStroke()
      p5.fill('#1A202C')
      p5.circle(height / 2, width / 2, width)
    }
    else {
      p5.background('#1A202C')
    }

    const density = 30
    const space = width / density

    for (let i = 0; i < p5.random(2, 7); i++) {
      lines.push(new FlowLine(p5, width, height, space))
    }



  };


  const draw = (p5: p5Types) => {
    lines.forEach(l => l.draw(p5, circular, circleRadius))
  };
  return <Sketch setup={setup} draw={draw} />
}

class FlowLine {
  points: p5Types.Vector[] = []
  redGradient: p5Types.Vector
  blueGradient: p5Types.Vector
  greenGradient: p5Types.Vector
  mult: number
  width: number;
  height: number;


  constructor(p5: p5Types, width: number, height: number, space: number) {

    for (let x = 0; x < width; x += space) {
      for (let y = 0; y < height; y += space) {
        const p = p5.createVector(x + p5.random(-10, 10), y + p5.random(-10, 10))
        this.points.push(p)
      }
    }
    this.redGradient = p5.createVector(p5.random(255), p5.random(255))
    this.greenGradient = p5.createVector(p5.random(255), p5.random(255))
    this.blueGradient = p5.createVector(p5.random(255), p5.random(255))
    this.mult = p5.random(0.002, 0.1)
    this.height = height
    this.width = width
  }


  draw(p5: p5Types, circular: boolean, circleRadius: number) {
    p5.noStroke()

    this.points.forEach(point => {
      const red = p5.map(point.x, 0, this.width, this.redGradient.x, this.redGradient.y)
      const green = p5.map(point.y, 0, this.height, this.greenGradient.x, this.greenGradient.y)
      const blue = p5.map(point.x, 0, this.width, this.blueGradient.x, this.blueGradient.y)
      const alpha = p5.map(p5.dist(this.width / 2, this.height / 2, point.x, point.y), 0, 350, 400, 0)

      p5.fill(red, green, blue, alpha)

      const angle = p5.map(p5.noise(point.x * this.mult, point.y * this.mult), 0, 1, 0, 720)
      point.add(p5.createVector(p5.cos(angle), p5.sin(angle)))
      if (!circular || p5.dist(this.width / 2, this.height / 2, point.x, point.y) < circleRadius) {
        p5.ellipse(point.x, point.y, 1)
      }

    })
  }
}