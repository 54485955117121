import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styles from "./animatedtext.module.css";

interface Props {
    text: string;
}

export const WrittenText = ({text}: Props) => {

    const ctrls = useAnimation();

    const { ref, inView } = useInView({

        threshold: 0.5,
    
        triggerOnce: true,
    
      });


      useEffect(() => {

        if (inView) {
    
          ctrls.start("visible");
    
        }
    
        if (!inView) {
    
          ctrls.start("hidden");
    
        }
    
      }, [ctrls, inView]);

    const wordAnimation = {
        hidden: {},
        visible: {}
    }

    const characterAnimation = {
        hidden: {
          opacity: 0,
          y: `0.25em`,
        }, 
        visible: {
          opacity: 1,
          y: `0em`,
          transition: {
            duration: 1,
            ease: [0.2, 0.65, 0.3, 0.9],
          },
        },
      };

    return <div aria-label={text} role="heading">
        {text.split(" ").map((word, index) => <motion.span
            className={styles.word}
            ref={ref}
            variants={wordAnimation}
            aria-hidden="true"
            key={index}
            initial="hidden"
            animate={ctrls}
            transition={{
                delayChildren: index * 0.25,
                staggerChildren: 0.05,
              }}
        >
            {word.split("").map((character, index) => 
                <motion.span
                    aria-hidden="true"
                    key={index}
                    variants={characterAnimation}
                >
                    {character}
                </motion.span>
            )}
        </motion.span>)}
    </div>

}

export const AppearingText = ({text}: Props) => {

    const ctrls = useAnimation();

    const { ref, inView } = useInView({

        threshold: 0.5,
    
        triggerOnce: true,
    
      });

      useEffect(() => {
        if (inView) {
          ctrls.start("visible");
        }
    
        if (!inView) {
          ctrls.start("hidden");
        }
    
      }, [ctrls, inView]);

    const wordAnimation = {
        hidden: {
            opacity: 0,
            y: 4
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.2,
                duration: 1,
                ease: [0.2, 0.65, 0.3, 1],
            },
        }
    }


    return <div aria-label={text} role="heading">
        {text.split(" ").map((word, index) => <motion.span
            className={styles.word}
            ref={ref}
            variants={wordAnimation}
            aria-hidden="true"
            key={index}
            initial="hidden"
            animate={ctrls}
        >
            {word}
        </motion.span>)}
    </div>

}