import React from 'react';

export const useInterval = (callback: () => void, delay: number) => {
    const savedCallback = React.useRef<any>();
  
    React.useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    React.useEffect(() => {
      const tick = () => {
          if(savedCallback.current) {
            savedCallback.current();
          }
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };