import React from 'react'
import { Link, Outlet } from 'react-router-dom';
import { PlaygroundRoutes } from '../App';
import styles from './playground.module.css';

export const Playground: React.FC = () => {
    return <>
        <nav className={styles.navbar}>
            <div className={styles.navbarContent}>
                <ul className={styles.links}>
                    <li>Playground:</li>
                    {Object.keys(PlaygroundRoutes).map(it => <li><Link to={(PlaygroundRoutes as any)[it]}>{it}</Link></li>)}
                </ul>
            </div>

        </nav>
        <Outlet />
    </>
}