import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { makeGrid } from '../utils';
import { GeneralSketchProps } from '../types';

interface BezierInfo {
    r: number;
    g: number;
    b: number;
}

export function FlowerSketch({ height, width }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {

    let start: number, end: number, seconds: number

    let flowers: Flower[][];

    const setup = (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(width, height).parent(canvasParentRef);
        p5.angleMode(p5.DEGREES)
        p5.background('#1A202C')
        seconds = 2
        start = p5.millis()
        end = start + 2000 * seconds

        flowers = makeGrid(2, () => new Flower(p5, width / 3))

    };

    let percentage = 0;

    const draw = (p5: p5Types) => {
        let lastPercentage: number = 0;
        if (p5.millis() < end) {
            lastPercentage = percentage
            percentage = p5.int((p5.millis() - start) / (20 * seconds))
        } else percentage = 100

        if (percentage !== 100) {
            flowers.forEach((row, x) => row.forEach((flower, y) =>
                flower.draw(p5, p5.createVector((((x + 1) * 2) - 1) * width / 4, (((y + 1) * 2) - 1) * height / 4), percentage, lastPercentage)))
        }
    }


    return <Sketch setup={setup} draw={draw} />
}



class Flower {
    angle: number;
    curves: p5Types.Vector[][];
    colors: BezierInfo[];
    size: number;

    constructor(p5: p5Types, size: number) {
        const nPoints = p5.int(size / 2)

        this.angle = 360 / ((p5.int(p5.random(1, 12)) + 1) * 2)

        let nCurves;
        if (this.angle > 59) {
            nCurves = p5.int(p5.random(0, 4)) + 2
        }
        else if (this.angle > 44) {
            nCurves = p5.int(p5.random(0, 4)) + 1
        } else nCurves = 2

        const bezierPoints: p5Types.Vector[][] = new Array(nCurves)
            .fill(0)
            .map(_ => new Array(2).fill(0).map(_ => p5.createVector(p5.int(p5.random(10, size / 2)), p5.int(p5.random(10, size / 4)))))


        this.curves = new Array(nCurves)
            .fill(0)
            .map((_, p) => {
                const points = bezierPoints[p]

                return new Array(nPoints).fill(0)
                    .map((_, i) =>
                        p5.createVector(
                            p5.bezierPoint(0, points[0].x, points[1].x, size / 2, i / nPoints),
                            p5.bezierPoint(0, points[0].y, points[1].y, 0, i / nPoints)
                        )
                    )
            }
            )

        this.colors = new Array(nCurves)
            .fill(0)
            .map(_ => (
                {
                    r: p5.int(p5.random(0, 255)),
                    g: p5.int(p5.random(0, 255)),
                    b: p5.int(p5.random(0, 255)),
                }))
        this.size = size
    }

    draw(p5: p5Types, position: p5Types.Vector, percentage: number, lastPercentage: number) {
        p5.translate(position.x, position.y)
        for (let d = 0; d < 360; d += this.angle) {
            p5.push()
            p5.rotate(d)
            this.drawFlowerSlice(p5, this.colors, this.curves, percentage, lastPercentage)
            p5.pop()
        }

        for (let d = 0; d < 360; d += this.angle) {
            p5.push()
            p5.rotate(d)
            p5.scale(1, -1)
            this.drawFlowerSlice(p5, this.colors, this.curves, percentage, lastPercentage)
            p5.pop()
        }
        p5.translate(-position.x, -position.y)
    }

    drawFlowerSlice = (p5: p5Types, colors: BezierInfo[], beziers: p5Types.Vector[][], percentage: number, lastPercentage: number) => {

        p5.noFill()

        beziers.forEach((bezierPoints, i) => {
            p5.stroke(colors[i].r, colors[i].b, colors[i].g)
            bezierPoints.slice(lastPercentage, percentage).forEach(v => {
                p5.ellipse(v.x, v.y, 1)
            })
        })
    }

}