import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { makeGrid } from '../utils';
import { GeneralSketchProps } from '../types';

enum TileState {
    ALIVE = 'ALIVE',
    DEAD = 'DEAD',
    INACTIVE = 'INACTIVE',
    OUT_OF_BOUNDS = 'OOB'
}

interface Tile {
    state: TileState;
    allegiance: number;
}


export function GameOfLife({ height, width }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {

    const nTilesInRow = 60

    const tileWidth = width / nTilesInRow
    const tileHeight = height / nTilesInRow
    const thresh = 0.1
    let tiles: Tile[][] = [[]]

    const next: Tile[][] = makeGrid(nTilesInRow, () => ({ state: TileState.INACTIVE, allegiance: 0 }));
    const setup = (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(width, height).parent(canvasParentRef);
        tiles = makeGrid(nTilesInRow, (x, y) => {
            const r = p5.random(1)
            return generateCellState(x, y, nTilesInRow, r, thresh)
        }
        )
        p5.angleMode(p5.DEGREES)
        p5.background('#1A202C')

    };

    let lastTick = 0;
    const performTick = (frameCount: number) => {
        if ((frameCount - lastTick) > 10) {
            lastTick = frameCount
            return true
        } return false
    }

    const draw = (p5: p5Types) => {
        p5.strokeWeight(0.1)
        if (performTick(p5.frameCount)) {
            tiles.forEach((row, x) => row.forEach((tile, y) => {
                const startX = x * tileWidth
                const startY = y * tileHeight

                if (tile.state === TileState.ALIVE) {
                    fillWithAlliegenceColor(p5, tile.allegiance)
                } else if (tile.state === TileState.INACTIVE) {
                    p5.fill(p5.color('#282c34'))
                } else if (tile.state === TileState.DEAD) {
                    p5.fill(100, 10, 10)
                } else if (tile.state === TileState.OUT_OF_BOUNDS) {
                    p5.fill(0, 0, 0)
                }

                p5.rect(startX, startY, tileWidth, tileHeight)

                next[x][y] = runLifeIteration(tiles, x, y)
            })
            )
            tiles = next
        }
    }
    return <Sketch setup={setup} draw={draw} />
}


const generateCellState = (x: number, y: number, size: number, rand: number, tresh: number): Tile => {
    if (x === 0 || y === 0 || x === size - 1 || y === size - 1) {
        return { state: TileState.OUT_OF_BOUNDS, allegiance: -1 }
    }
    return {
        state: rand < tresh ? TileState.ALIVE : TileState.INACTIVE,
        allegiance: generateAllegience(x, y, size)
    }
}

const generateAllegience = (x: number, y: number, tilesInRow: number): number => {
    const mid = tilesInRow / 2
    if (x < mid && y < mid) {
        return 1
    } else if (x > mid && y < mid) {
        return 2
    } else if (x < mid && y > mid) {
        return 3
    } else return 4
}

const fillWithAlliegenceColor = (p5: p5Types, allegience: number) => {
    if (allegience === 1) {
        p5.fill(255, 255, 0)
    }
    else if (allegience === 2) {
        p5.fill(0, 255, 0)
    }
    else if (allegience === 3) {
        p5.fill(0, 0, 255)
    }
    else if (allegience === 4) {
        p5.fill(0, 255, 255)
    }
}

const runLifeIteration = (tiles: Tile[][], x: number, y: number): Tile => {
    const currentTile = tiles[x][y]
    if (tiles[x][y].state === TileState.OUT_OF_BOUNDS) return currentTile

    const neighbours = getNeighbours(tiles, x, y)

    if (tiles[x][y].state === TileState.ALIVE) {
        const nAliveNeighbours =
            neighbours.filter(it => it.state === TileState.ALIVE).length

        if (nAliveNeighbours < 2 || nAliveNeighbours > 3) {
            return { ...currentTile, state: TileState.DEAD }
        } else return currentTile
    } else if (tiles[x][y].state === TileState.DEAD || tiles[x][y].state === TileState.INACTIVE) {
        const aliveNeighbours =
            neighbours.filter(it => it.state === TileState.ALIVE)

        if (aliveNeighbours.length === 3) {
            let max = 0
            let maxValue = 0
            for (let i = 1; i < 5; i++) {
                const newVal = aliveNeighbours.filter(tile => tile.allegiance === i).length
                if (newVal > maxValue) {
                    maxValue = newVal
                    max = i
                }
            }

            return { state: TileState.ALIVE, allegiance: max }
        } else return currentTile
    }
    return currentTile
}

const getNeighbours = (tiles: Tile[][], x: number, y: number): Tile[] => {
    const top = tiles[x - 1].slice(y - 1, y + 2)
    const bot = tiles[x + 1].slice(y - 1, y + 2)
    const sides = [tiles[x][y - 1], tiles[x][y + 1]]
    return [...top, ...bot, ...sides].filter(it => it.state !== TileState.OUT_OF_BOUNDS)
}