import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { GeneralSketchProps } from '../types';


/*
const mandelbrotCoordinatePairs = [
    [0.25025, 0],
    [-0.75, 0.016],
    [-0.75, -0.016],
    [-1.25, 0.008],
    [-1.25, -0.008]
]

const startingSubset = (p5: p5Types): p5Types.Vector => {
    return p5.createVector(0, 0, 2)
}
*/

const findRandomSubset = (p5: p5Types): p5Types.Vector => {
    if (p5.random() < 0.7) {
        return findRandomSubset1(p5)
    } else return findRandomSubset2(p5)
}

const findRandomSubset1 = (p5: p5Types): p5Types.Vector => {
    const theta = p5.random(0, 360)

    const x = (p5.sin(theta) * 0.252) - 1
    const y = (p5.cos(theta) * 0.252)

    return p5.createVector(x, y, p5.random(0.007, 0.1))
}

const findRandomSubset2 = (p5: p5Types): p5Types.Vector => {
    const theta = 179
    const r = 0.295

    const theta2 = 183
    const r2 = 0.325

    const x1 = (p5.sin(theta) * r) - 1
    const y1 = (p5.cos(theta) * r)

    const x2 = (p5.sin(theta2) * r2) - 1
    const y2 = (p5.cos(theta2) * r2)

    const x = p5.random(x1, x2)
    const y = (x - x2) * (y2 - y1) / (x2 - x1) + y2

    const span = p5.random(0.002, 0.007)

    return p5.createVector(x, y, span)
}

export function Mandelbrot({ height, width }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {

    let myImage: p5Types.Image;

    const setup = (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(width, height).parent(canvasParentRef);
        p5.angleMode(p5.DEGREES)
        myImage = p5.createImage(width * 3, height * 3)

        drawMandelBrot(p5, myImage, findRandomSubset(p5))

    };

    let currentIter = 0;
    const draw = (p5: p5Types) => {
        if (myImage) {
            p5.image(myImage, 0, 0, width, height, 0 + currentIter / 2, 0 + currentIter / 2, myImage.width - currentIter, myImage.height - currentIter)

        }
        currentIter++
        //p5.stroke(255,0,0)
        //p5.noFill()
        //p5.circle(width / 2, height / 2, 10)

    }

    return <Sketch setup={setup} draw={draw} />
}

const drawMandelBrot = (p5: p5Types, image: p5Types.Image, center: p5Types.Vector) => {
    const span = center.z
    const height = image.height
    const width = image.width

    const mandelbrotMaxIter = 200
    const mandelbrotInfinity = 16

    const ranges: { from: p5Types.Color, to: p5Types.Color }[] = [
        { from: p5.color(100, 0, 255), to: p5.color(255, 255, 0) },
        { from: p5.color(255, 255, 255), to: p5.color(255, 80, 0) },
        { from: p5.color(255, 255, 255), to: p5.color(72, 100, 255) }
    ]

    const { from, to } = p5.random(ranges)

    let currentColor: p5Types.Color

    image.loadPixels()
    for (let i = 0; i < width; i++) {
        for (let j = 0; j < height; j++) {
            // map pixel value to coordinate in mandelbrot computation
            const x = p5.map(i, 0, width, center.x - span, center.x + span);
            const y = p5.map(j, 0, height, center.y - span, center.y + span);

            let nIterationsMandelbrot = computeMandelBrotIteration(p5, x, y, mandelbrotMaxIter, mandelbrotInfinity);

            if (nIterationsMandelbrot === mandelbrotMaxIter) {
                // max iterations --> black
                currentColor = p5.color(0, 0, 0);
            }
            else {
                // color by iteration count
                let t = mandelbrotMaxIter / 20;
                currentColor = p5.lerpColor(from, to, p5.map(nIterationsMandelbrot % t, 0, t, 0, 1));
            }

            const pixelIndex = (i + j * image.width) * 4;

            // update pixel
            const [r, g, b] = (currentColor as any).levels
            image.pixels[pixelIndex + 0] = r
            image.pixels[pixelIndex + 1] = g
            image.pixels[pixelIndex + 2] = b
            image.pixels[pixelIndex + 3] = 255;
        }
    }
    image.updatePixels()
}

// compute the maximum number of iterations n to reach infinity
const computeMandelBrotIteration = (p5: p5Types, x: number, y: number, mandelbrotMaxIter: number, mandelbrotInfinity: number): number => {

    // starting from c = x + i * y
    let n = 0;
    let f_real = 0;
    let f_imag = 0;
    let f_square_real = 0;
    let f_square_imag = 0;
    while (n < mandelbrotMaxIter) {
        // f = f^2 + c
        f_square_real = f_real * f_real - f_imag * f_imag
        f_square_imag = 2 * f_real * f_imag
        f_real = f_square_real + x
        f_imag = f_square_imag + y
        if (p5.sqrt(f_real * f_real + f_imag * f_imag) > mandelbrotInfinity) {
            // big enough distance to be called infinity
            break;
        }
        n++;
    }
    return n;
};