import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { GeneralSketchProps } from '../types';

export function GaussianDistribution({ height, width }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {

  const length = 100

  const stepsize = width / length

  const nIterations = 400
  let currentIteration = 0

  const numbers = new Array(length).fill(0)

  const setup = (p5: p5Types, canvasParentRef: Element) => {
    p5.createCanvas(width, height).parent(canvasParentRef);
    p5.background('#1A202C')
  };


  const draw = (p5: p5Types) => {
    p5.background(0)
    p5.noStroke()
    p5.fill(255)
    numbers.map((n, i) => p5.ellipse(i * stepsize, (height - 10) - n * 2, 4, 4))

    if (currentIteration < nIterations) {
      const values = new Array(5).fill(0)
      values.forEach(_ => {
        const gauss = Math.round(p5.randomGaussian(length / 2, 15))
        if (gauss < 100) {
          numbers[gauss]++
        }
      }
      )

    }
    currentIteration++

  }

  return <Sketch setup={setup} draw={draw} />
}