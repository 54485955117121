let PROJECT_ID = "gr2rrxmb";
let DATASET = "production";
let QUERY = encodeURIComponent('*');

// Compose the URL for your project's endpoint and add the query
let URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;


export interface BlogInfo {
    name: string;
    preview: SanityImageRef;
    description: string;
    cardUrl: string;
    _createdAt: string;
}

export interface SanityImageRef {
    asset: {
        _ref: string;
    }
}

export interface MyTexts {
    blog: BlogInfo[];
    sketches: SketchInformation[],
    images: SanityImage[]
}

export interface SketchInformation {
    name: string;
    description: string;
    _createdAt: string;
}

export const fallbackText: SketchInformation[] = [
    {
        name: "Mandelbrot",
        description: "",
        _createdAt: (new Date()).toString()
    }
]

export const fallbackFallback: SketchInformation = {
    name: "",
    description: "",
    _createdAt: (new Date()).toString()
}

export const fetchTextFromSanity = (): Promise<{result: MyTexts}> =>
 fetch(URL)
    .then(
        (res) => (res.json() as unknown as Promise<SanityRawText>).then(result => 
            ({
                result: {
                    sketches: result.result.filter(s => s._type === "sketch") as unknown as SketchInformation[],
                    blog: (result.result.filter(s => s._type === "blog-card")  as unknown as BlogInfo[]).sort((a,b) => Date.parse(b._createdAt) - Date.parse(a._createdAt)),
                    images: result.result.filter(s => s._type === "sanity.imageAsset")  as unknown as SanityImage[]
                }
            })
            )
    )
        

interface SanityRawText {
    result: {
        [k: string]: string
        _type: string;
    }[]
}

export interface SanityImage {
    _id: string;
    url: string;
}