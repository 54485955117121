import React, { useEffect } from "react";
import {useAnimation, motion} from "framer-motion";
import { useInView } from "react-intersection-observer";

interface Props {
    children: React.ReactNode;
    delay?: number;
    style?: object;
}

export const AnimatedEntry = ({ children, delay, style }: Props) => {

    const ctrls = useAnimation();

    const { ref, inView } = useInView({

        threshold: 0.5,
    
        triggerOnce: true,
    
      });


      useEffect(() => {

        if (inView) {
    
          ctrls.start("visible");
    
        }
    
        if (!inView) {
    
          ctrls.start("hidden");
    
        }
    
      }, [ctrls, inView]);

    const wordAnimation = {
        hidden: {
            opacity: 0,
            y: 4
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: delay ?? 0.5,
                duration: 1,
                ease: [0.2, 0.65, 0.3, 1],
            },
        }
    }


    return <div style={{marginBottom: "13px", ...style}}>
       <motion.div
            style={{display: "inline-block", marginRight: "0.25em"}}
            variants={wordAnimation}
            aria-hidden="true"
            initial="hidden"
            animate={ctrls}
            ref={ref}
        >
            {children}
        </motion.div>
    </div>

}