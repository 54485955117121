import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { GeneralSketchProps } from '../types';

enum Rotation {
    CLOCKWISE = "CLOCKWISE",
    COUNTER_CLOCKWISE = "COUNTER_CLOCKWISE"
}


export function Gears({ height, width }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {

    let gears: Gear[] = []
    const setup = (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(width, height).parent(canvasParentRef);
        p5.angleMode(p5.DEGREES);

        const firstGear = Gear.from(p5, p5.createVector(width / 2, height / 2), 25, Rotation.CLOCKWISE, 0)

        gears.push(firstGear)

        for (let i = 0; i < 4; i++) {
            gears.push(gears[i].spawn(p5, 25 - i * 5))
        }
        p5.background('#1A202C')
    };

    let t = 0;

    const draw = (p5: p5Types) => {
        p5.background('#1A202C')
        gears.forEach(g => g.draw(p5, t))
        t++;
    };
    return <Sketch setup={setup} draw={draw} />
}

class Gear {
    center: p5Types.Vector;
    r: number;
    g: number;
    b: number;
    radius: number;
    rotation: Rotation;
    teethAngle: number;
    startingOffset: number;



    constructor(p5: p5Types, center: p5Types.Vector, radius: number, rotation: Rotation, startingOffset: number, r: number, g: number, b: number) {
        this.center = center;
        this.r = r;
        this.g = g;
        this.b = b;
        this.radius = radius;
        this.rotation = rotation;
        this.teethAngle = 30
        this.startingOffset = startingOffset
    }

    static from(p5: p5Types, center: p5Types.Vector, radius: number, rotation: Rotation, startingOffset: number): Gear {
        return new Gear(p5, center, radius, rotation, startingOffset, p5.random(255), p5.random(255), p5.random(255))
    }

    draw(p5: p5Types, delta: number) {
        p5.push()



        p5.fill(this.r, this.g, this.b)
        p5.translate(this.center.x, this.center.y)

        if (this.rotation === Rotation.CLOCKWISE) {
            p5.rotate(this.startingOffset + delta)
        } else {
            p5.rotate(this.startingOffset - 1 * delta)
        }

        p5.circle(0, 0, this.radius * 2)
        for (let i = 0; i < 360 / this.teethAngle; i++) {
            if (i % 2) {
                p5.arc(0, 0, this.radius * 2 + 10, this.radius * 2 + 10, i * this.teethAngle, (i + 1) * this.teethAngle)
            }
        }
        p5.translate(-this.center.x, -this.center.y)
        p5.pop()
    }

    spawn(p5: p5Types, radius: number): Gear {
        let angle;
        angle = p5.int(p5.random(0, 4)) * 60

        const x = this.center.x + (p5.cos(angle) * (radius + this.radius + 5))
        const y = this.center.y + (p5.sin(angle) * (radius + this.radius + 5))
        let rotation;
        if (this.rotation === Rotation.CLOCKWISE) {
            rotation = Rotation.COUNTER_CLOCKWISE
        } else {
            rotation = Rotation.CLOCKWISE
        }


        return Gear.from(p5, p5.createVector(x, y), radius, rotation, 0)
    }

}