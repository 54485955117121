import React, { useEffect, useState } from 'react';
import { SketchStacker } from '../SketchStacker';
import { GeneralSketchProps } from '../types';
import styles from './generator.module.css';


interface Props {
  height: number;
  width: number;
  sketchId: string;
  MySketch: (p: GeneralSketchProps) => React.ReactElement
  shouldRefresh?: boolean
  refreshTimer?: number
}

export function SketchGenerator({height, width,sketchId, MySketch, shouldRefresh = true, refreshTimer}: Props): React.ReactElement {
  const [refresh, setRefresh] = useState<boolean>(false)
  
  useEffect(() => {
    if(refresh) {
      setRefresh(false)
    }
  }, [refresh])

  return (
    <div className={styles.container}>
        <div style={{width, height}}>
          {!refresh && <MySketch height={height} width={width}/>}
        </div>
        {shouldRefresh && <SketchStacker sketchId={sketchId} triggerRefresh={() => setRefresh(true)} refreshTimer={refreshTimer} height={height} width={width} background={"#282c34"}/>}
    </div>
  );
}

