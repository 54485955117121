import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Colormode } from '../components/Colormode';
import styles from './navbar.module.css';

export const AppNavbar = () => {

    return <>
        <nav className={styles.navbar}>
            <div className={styles.navbarContent}>
                <ul className={`${styles.links}`}>
                    <li><Link to={"/gallery/flow"}>Gallery</Link></li>
                    <li><a href='https://cv.sidelinehub.com'>About me</a></li>
                    <li><Colormode /></li>
                </ul>
            </div>
        </nav>
        <Outlet />
    </>
}