import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { GeneralSketchProps } from '../types';

export function PerlinShapes({ height, width }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {


  const setup = (p5: p5Types, canvasParentRef: Element) => {
    p5.createCanvas(width, height).parent(canvasParentRef);
    p5.stroke('#282c34')
    p5.background('#1A202C')
  };


  const draw = (p5: p5Types) => {
    p5.background('#1A202C')
    p5.push()
    p5.translate(300, 300)
    p5.translate(-100, -150)
    p5.rotate(1)
    p5.fill(255, 120, 120)
    polygon(p5, 0, 0, 80, 8)
    p5.pop()


  };
  return <Sketch setup={setup} draw={draw} />
}

const polygon = (p5: p5Types, x: number, y: number, radius: number, nEdges: number) => {
  const angle = p5.TWO_PI / nEdges

  const pointInNoiseSpace = p5.frameCount / 200
  p5.rotate(p5.frameCount / 200)

  const nBoxes = 50
  const boxSize = 3
  const max = 70

  p5.beginShape()
  let a = 0
  while (a < p5.TWO_PI) {
    const startX = x + p5.cos(a) * radius
    const endX = x + p5.cos(a + angle) * radius
    const startY = y + p5.sin(a) * radius
    const endY = x + p5.sin(a + angle) * radius

    for (let i = 0; i < nBoxes; i++) {
      const currentX = p5.lerp(startX, endX, i / nBoxes)
      const currentY = p5.lerp(startY, endY, i / nBoxes)
      const xOffset = p5.map(p5.cos(i), -1, 1, 0, 3)
      const yOffset = p5.map(p5.sin(i), -1, 1, 0, 3)

      const noise = p5.noise(pointInNoiseSpace + xOffset, pointInNoiseSpace + yOffset)

      const offsetHeight = p5.map(noise, 0, 1, -max, max)

      p5.rect(currentX, currentY, offsetHeight, boxSize)
    }


    a += angle
  }
  p5.endShape()
}