import React, { useContext } from 'react'
import { TextContext } from '../TextProvider'
import styles from './frontpage.module.css'
import { AppearingText, WrittenText } from '../components/AnimatedText'
import { Pipes } from '../sketches/components/Pipes'
import { useInView } from 'react-intersection-observer'
import { AnimatedEntry } from '../components/AnimatedEntry'
import { useViewport } from '../useViewport'

export function Frontpage() {

    const text = useContext(TextContext)

    return <main>
        <div className={styles.overlay}>
            <div className={styles.definition}>
                <div>
                <span className={styles.deftitle}>
                    <h2 className={styles.sidelineTitle}>
                        <WrittenText text='Sideline'/>
                    </h2>
                    <p className={styles.sidelineNoun}>Noun</p>
                </span>
                <ul className={styles.deflist}>
                    <li><AppearingText text="A business or activity pursued in addition to one's regular occupation"/></li>
                </ul>
                </div>
                <div className={styles.illustration}>
                </div>
               
            </div>
           
            <div className={styles.container}>
            <p className={styles.ingress}>
                    Sidelinehub is intended to be a collection of my own hobby projects and sidelines.
                </p>
            <div className={styles.divider}/>    
            <ArtsBanner/>
            <div className={styles.divider}/>    
            <CvBanner/>
            </div>

        </div>
    </main>
}

const ArtsBanner = () => {
    const { ref, inView } = useInView({

        threshold: 0.2,
    
        triggerOnce: false,
    
      });

        const {sm} = useViewport();
        const size = sm ? 200 : 400;


    return <a href={"/gallery/flow"} >
        <div className={styles.generativeArtBanner}>
                <div ref={ref} className={styles.generativeArtBannerText} >
                    <h2 className={styles.sidelineTitle}>
                        <WrittenText text='Generative art'/>
                    </h2>
                    <AnimatedEntry>
                        <p>An arts gallery for generative art</p>
                    </AnimatedEntry>
                </div>
                <div className={styles.canvasbanner}>
                    {inView && <Pipes height={size} width={size}/> }
                    {!inView && <div style={{height: size, width: size, background: "#1A202C"}}/> }
                </div>
        </div>
    </a>
}


const CvBanner = () => {
    const { ref, inView } = useInView({

        threshold: 0.2,
    
        triggerOnce: false,
    
      });

        const {sm} = useViewport();
        const size = sm ? 200 : 400;


    return <a href={"https://cv.sidelinehub.com"} >
        <div className={styles.generativeArtBanner}>
                <div ref={ref} className={styles.generativeArtBannerText} >
                    <h2 className={styles.sidelineTitle}>
                        <WrittenText text='My background'/>
                    </h2>
                    <AnimatedEntry>
                        <p>An interactive look into my background</p>
                    </AnimatedEntry>
                </div>
                <div className={styles.canvasbanner}>
                   <div className={styles.myImage} style={{height: size, width: size }}/>
                </div>
        </div>
    </a>
}