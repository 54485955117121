import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { GeneralSketchProps } from '../types';

interface MyCircle {
    centerX: number;
    centerY: number;
    radius: number;
    color: p5Types.Color;
    seed: number;
    type: CircleType
    finished: boolean;
}

enum CircleType {
    PERLIN,
    CIRCLE
}

export function CircleFiller({ height, width }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {

    let circles: MyCircle[] = []

    const nCircles = 100
    const nToAdd = 10
    const chanceOfPerlin = 0.3

    const allowedOffset = 10

    const setup = (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(width, height).parent(canvasParentRef);
        p5.background('#1A202C')
    };


    const draw = (p5: p5Types) => {
        p5.blendMode(p5.BLEND)
        p5.background(130)
        p5.blendMode(p5.HARD_LIGHT)

        if (circles.length < nCircles) {
            for (let i = 0; i < nToAdd; i++) {
                const x = p5.random(0, width)
                const y = p5.random(0, height)
                const r = p5.random(0, 255)
                const g = p5.random(0, 255)
                const b = p5.random(0, 255)
                circles.push({
                    centerX: x,
                    centerY: y,
                    radius: 0,
                    color: p5.color(r, g, b),
                    seed: p5.random(0, 1000),
                    type: p5.random() < chanceOfPerlin ? CircleType.PERLIN : CircleType.CIRCLE,
                    finished: false
                })
            }
        }

        circles = circles.map(c => {
            let finished = c.finished
            if (!c.finished) {
                for (let other of circles) {
                    if (other.centerX !== c.centerX && other.centerY !== c.centerY &&
                        p5.dist(c.centerX, c.centerY, other.centerX, other.centerY) < (c.radius + other.radius - allowedOffset)) {
                        finished = true
                    }
                }
            }

            p5.fill(c.color)
            if (c.type === CircleType.PERLIN) {
                createPerlinCircle(p5, c, c.seed)
            }
            if (c.type === CircleType.CIRCLE) {
                p5.circle(c.centerX, c.centerY, c.radius * 2)
            }
            return { ...c, radius: finished ? c.radius : c.radius + 1, finished: finished }
        })



    };
    return <Sketch setup={setup} draw={draw} />
}


const createPerlinCircle = (p5: p5Types, circle: MyCircle, seed: number) => {
    const step = 0.02
    let t = p5.map(p5.noise(seed), 0, 1, 0, 200);
    let u = p5.map(p5.noise(seed), 0, 1, 100, 500);

    p5.beginShape();
    for (let a = 0; a < p5.HALF_PI; a += step) {

        let offset = p5.map(p5.noise(t, u), 0, 1, 0, circle.radius);
        let x = p5.cos(a) * (circle.radius - offset) + circle.centerX;
        let y = p5.sin(a) * (circle.radius - offset) + circle.centerY;
        p5.vertex(x, y);
        t += 0.01;
    }
    for (let a = p5.HALF_PI; a < p5.PI; a += step) {
        let offset = p5.map(p5.noise(t, u), 0, 1, 0, circle.radius);
        let x = p5.cos(a) * (circle.radius - offset) + circle.centerX;
        let y = p5.sin(a) * (circle.radius - offset) + circle.centerY;
        p5.vertex(x, y);
        u += 0.01;
    }
    for (let a = p5.PI; a < p5.HALF_PI * 3; a += step) {
        let offset = p5.map(p5.noise(t, u), 0, 1, 0, circle.radius);
        let x = p5.cos(a) * (circle.radius - offset) + circle.centerX;
        let y = p5.sin(a) * (circle.radius - offset) + circle.centerY;
        p5.vertex(x, y);
        t -= 0.01;
    }
    for (let a = p5.HALF_PI * 3; a < p5.TWO_PI; a += step) {
        let offset = p5.map(p5.noise(t, u), 0, 1, 0, circle.radius);
        let x = p5.cos(a) * (circle.radius - offset) + circle.centerX;
        let y = p5.sin(a) * (circle.radius - offset) + circle.centerY;
        p5.vertex(x, y);
        u -= 0.01;
    }
    p5.endShape(p5.CLOSE);
}