import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { GeneralSketchProps } from '../types';




export function Maze({ height, width }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {

    const maxRadius = height / 2 - 10

    let dotPositions: DynamicCircle[] = []
    const setup = (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(width, height).parent(canvasParentRef);
        p5.angleMode(p5.DEGREES);
        const nArcs = p5.random(5, 20)
        const diff = height / nArcs
        for (let x = 0; x < nArcs; x++) {

            dotPositions.push(
                DynamicCircle.from(
                    p5,
                    height / 2,
                    width / 2,
                    p5.random(0, 180),
                    p5.random(0, 360),
                    (x / 2) * diff,
                    10,
                    maxRadius,
                )
            )
        }
        p5.background('#1A202C')
    };

    let angle = 0.0;
    let maxIter = 5
    let currentIter = 0
    const draw = (p5: p5Types) => {
        p5.noFill()
        dotPositions.forEach(p => {
            p.animate(p5, angle)
            p.show(p5)
        })
        angle += 2

        if (angle > 100) {
            dotPositions.forEach(p => p.drawLine(p5))
        }
        if (angle === 126 && currentIter < maxIter) {
            dotPositions = [...dotPositions, ...dotPositions.map(p => p.createChild(p5, maxRadius))]
            angle = 0.0
            currentIter += 1
        }
    };
    return <Sketch setup={setup} draw={draw} />
}


class DynamicCircle {
    center: p5Types.Vector;
    current: number;
    r: number;
    g: number;
    b: number;
    radius: number;
    endRadius: number;
    start: number;
    end: number;
    startLine: p5Types.Vector;
    currentLine: p5Types.Vector;
    endLine: p5Types.Vector;
    completed: boolean;



    constructor(p5: p5Types, x: number, y: number, start: number, end: number, radius: number, segment: number, maxRadius: number, r: number, g: number, b: number) {
        if (start < end) {
            this.start = start;
            this.end = end;
        } else {
            this.start = end;
            this.end = start;
        }

        this.center = p5.createVector(x, y)
        this.current = this.start;
        this.r = r;
        this.g = g;
        this.b = b;
        if (radius > maxRadius) {
            this.radius = maxRadius
            this.endRadius = maxRadius - p5.abs(segment)
        } else if ((radius + segment) > maxRadius) {
            this.radius = radius;
            this.endRadius = radius - segment;
        }
        else {
            this.radius = radius;
            this.endRadius = radius + segment;
        }
        this.startLine = p5.createVector(p5.cos(this.end) * this.radius, p5.sin(this.end) * this.radius)
        this.endLine = p5.createVector(p5.cos(this.end) * (this.endRadius), p5.sin(this.end) * (this.endRadius))

        this.currentLine = this.startLine
        this.completed = false
    }

    static from(p5: p5Types, x: number, y: number, start: number, end: number, radius: number, segment: number, maxRadius: number): DynamicCircle {
        return new DynamicCircle(p5, x, y, start, end, radius, segment, maxRadius, p5.random(255), p5.random(255), p5.random(255))
    }

    show(p5: p5Types) {
        if (!this.completed) {
            p5.stroke(this.r, this.g, this.b)
            p5.arc(this.center.x, this.center.y, this.radius * 2, this.radius * 2, this.start, this.current)
        }
    }

    drawLine(p5: p5Types) {
        if (!this.completed) {
            p5.stroke(this.r, this.g, this.b)
            p5.push()
            p5.translate(this.center.x, this.center.y)
            p5.line(this.startLine.x, this.startLine.y, this.currentLine.x, this.currentLine.y)
            p5.translate(-this.center.x, -this.center.y)
            p5.pop()

        }
    }

    animate(p5: p5Types, angle: number) {
        this.currentLine = p5.createVector(
            p5.map(angle, 100, 125, this.startLine.x, this.endLine.x, true),
            p5.map(angle, 100, 125, this.startLine.y, this.endLine.y, true),
        )
        this.current = p5.map(angle, 0, 100, this.start, this.end, true);
    }

    createChild(p5: p5Types, maxRadius: number): DynamicCircle {
        this.completed = true
        return new DynamicCircle(p5, this.center.x, this.center.y, this.end, this.end + p5.random(255), this.endRadius, p5.random(-30, 30), maxRadius, this.r, this.g, this.b)
    }

}