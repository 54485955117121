import React, { createContext, useEffect, useState } from "react";
import { fetchTextFromSanity, MyTexts, SanityImageRef } from "./api";


export const TextContext = createContext<MyTexts>({
    sketches: [],
    blog: [],
    images: []
})

interface Props {
    children: React.ReactElement
}

export const TextProvider: React.FC<Props> = ({children}: Props) => {
    const [text, setText] = useState<MyTexts>({
        sketches: [],
        blog: [],
        images: []
    })

    useEffect(() => {
        fetchTextFromSanity().then(
            res => setText(
                window.location.origin === 'http://localhost:3000' ? mapToLocal(res.result) : res.result
            )
            )
      }, [])

    return <TextContext.Provider value={text}>
        {children}
    </TextContext.Provider>
}

const mapToLocal = (texts: MyTexts): MyTexts => {
    return {...texts, blog: texts.blog.map(blog => ({...blog, cardUrl: blog.cardUrl.replace('www.sidelinehub.com', 'localhost:3000')}))}
}


export const getImageFromRef = (ref: SanityImageRef, text: MyTexts) => text.images.find(i => i._id === ref.asset._ref)?.url