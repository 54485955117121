import './App.css';
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { GalleryNavbar } from './templates/GalleryNavbar';
import { SketchTemplate } from './templates/SketchTemplate';
import { FlowFieldSketch } from './sketches/components/FlowField';
import { FlowerSketch } from './sketches/components/Flower';
import { Pipes } from './sketches/components/Pipes';
import { Maze } from './sketches/components/Maze';
import { Tapestry } from './sketches/components/Tapestry';
import { GameOfLife } from './sketches/components/GameOfLife';
import { useViewport } from './useViewport';
import { Trail } from './sketches/components/Trail';
import { ParametricSketch } from './sketches/components/Parametric';
import { Playground } from './templates/Playground';
import { CircleFiller } from './sketches/components/CircleFiller';
import { Mandelbrot } from './sketches/components/Mandelbrot';
import { TileMap } from './sketches/components/TileMap';
import { TileMapTriangles } from './sketches/components/TileMapTriangles';
import { GaussianDistribution } from './sketches/components/GaussianDistribution';
import { PerlinShapes } from './sketches/components/PerlinShapes';
import { GeneralSketchProps } from './sketches/types';
import { TextProvider } from './TextProvider';
import { Gears } from './sketches/components/Gears';
import { Waves } from './sketches/components/Waves';
import { AppNavbar } from './templates/AppNavbar';
import { Frontpage } from './templates/Frontpage';
import { RainbowRoad } from './sketches/components/RainbowRoad';
import { Brush } from './sketches/components/Brush';

export interface SketchPageMetadata {
  name: string,
  route: string,
  ignoredFromLayering?: boolean,
  refreshTimer?: number,
  shouldRefresh?: boolean,
  element: (p: GeneralSketchProps) => React.ReactElement
}

export const MyCompletedSketches: SketchPageMetadata[] = [
  { name: "Flow", route: "flow", element: FlowFieldSketch, refreshTimer: 10000 },
  { name: "Flower", route: "flower", element: FlowerSketch },
  { name: "Pipes", route: "pipes", element: Pipes, refreshTimer: 7000 },
  { name: "Maze", route: "maze", element: Maze, refreshTimer: 9000 },
  { name: "Trail", route: "trail", element: Trail },
  { name: "Tapestry", route: "tapestry", element: Tapestry, ignoredFromLayering: true, refreshTimer: 7000 },
  { name: "Mandelbrot", route: "mandelbrot", element: Mandelbrot, ignoredFromLayering: true, refreshTimer: 8000 },
  { name: "Rainbow Roads", route: "rainbow-roads", element: RainbowRoad, refreshTimer: 13000 }
]

export const PlaygroundRoutes = {
  "Parametric": "parametric",
  "Brush": "brush",
  "Game of life": "game-of-life",
  "Circles": "circles",
  "Fractals": "fractals",
  "Tiles": "tiles",
  "Triangles": "triangles",
  "Gaussian": "gaussian",
  "Shapes": "shapes",
  "Gears": "gears",
  "Waves": "waves"
}

function App() {
  const { height, width, sm } = useViewport()

  const size = sm ? Math.round(width * 0.8) : Math.round(height * 0.7)

  return (
    <TextProvider>
      <ChakraProvider>


        <BrowserRouter>

          <Routes>
            <Route path="" element={<AppNavbar />}>
              <Route path="" element={<Frontpage />} />
            </Route>
            <Route path="gallery" element={<GalleryNavbar />}>
              {MyCompletedSketches.map(sketch =>
                <Route path={sketch.route} element={<SketchTemplate sketchId={sketch.name} height={size} width={size} sketch={sketch.element} refreshTimer={sketch.refreshTimer} shouldRefresh={sketch.shouldRefresh} />} />
              )}
            </Route>
            <Route path={"playground"} element={<Playground />}>
              <Route path={PlaygroundRoutes.Parametric} element={<SketchTemplate sketchId={'parametric'} height={size} width={size} sketch={ParametricSketch} />} />
              <Route path={PlaygroundRoutes.Brush} element={<SketchTemplate sketchId={'brush'} height={size} width={size} sketch={Brush} refreshTimer={10000} />} />
              <Route path={PlaygroundRoutes['Game of life']} element={<SketchTemplate sketchId={'gol'} shouldRefresh={false} height={size} width={size} sketch={GameOfLife} />} />
              <Route path={PlaygroundRoutes.Circles} element={<SketchTemplate sketchId={'circ'} height={size} width={size} sketch={CircleFiller} />} />
              <Route path={PlaygroundRoutes.Fractals} element={<SketchTemplate sketchId={'frac'} height={size} width={size} sketch={Mandelbrot} />} />
              <Route path={PlaygroundRoutes.Tiles} element={<SketchTemplate sketchId={'tile'} height={size} width={size} sketch={TileMap} />} />
              <Route path={PlaygroundRoutes.Triangles} element={<SketchTemplate sketchId={'tri'} height={size} width={size} sketch={TileMapTriangles} />} />
              <Route path={PlaygroundRoutes.Gaussian} element={<SketchTemplate sketchId={'gauss'} height={size} width={size} sketch={GaussianDistribution} />} />
              <Route path={PlaygroundRoutes.Shapes} element={<SketchTemplate sketchId={'shapes'} height={size} width={size} sketch={PerlinShapes} />} />
              <Route path={PlaygroundRoutes.Gears} element={<SketchTemplate sketchId={'gears'} height={size} width={size} sketch={Gears} />} />
              <Route path={PlaygroundRoutes.Waves} element={<SketchTemplate sketchId={'waves'} height={size} width={size} sketch={Waves} shouldRefresh={false} />} />
            </Route>
            <Route
              path="*"
              element={<Navigate to="/" replace />}
            />
          </Routes>
        </BrowserRouter>

      </ChakraProvider>
    </TextProvider>

  );
}



export default App;

