import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { makeGrid } from '../utils';
import { GeneralSketchProps } from '../types';

export function TileMap({ height, width }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {

    const nTilesInRow = 10

    const tileWidth = width / nTilesInRow

    let tiles: TileWithCircle[] = []

    const setup = (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(width, height).parent(canvasParentRef);
        tiles = makeGrid(nTilesInRow, (x, y) => {
            return new TileWithCircle(
                p5,
                x,
                y,
                tileWidth,
                p5.random(255),
                p5.random(255),
                p5.random(255)

            )
        }
        ).flat()
        p5.angleMode(p5.DEGREES)
        p5.background('#1A202C')

    }

    let t = 0;

    const draw = (p5: p5Types) => {
        p5.noStroke()
        p5.blendMode(p5.DIFFERENCE)
        if (t === 0) {
            tiles.forEach(tile => tile.drawInitial(p5))
        }
        tiles.forEach(tile => tile.draw(p5, t))
        t++
    };
    return <Sketch setup={setup} draw={draw} />
}

class TileWithCircle {
    diagonal: boolean;
    size: number;
    maxScale: number;
    red: number;
    green: number;
    blue: number;
    startX: number;
    startY: number;
    centerX: number;
    centerY: number;

    constructor(p5: p5Types, x: number, y: number, tilewidth: number, r: number, g: number, b: number) {
        this.red = r;
        this.green = g
        this.blue = b
        this.maxScale = p5.random(0.40, 0.60)
        this.diagonal = p5.random() < 0.5;


        this.centerX = (x + 0.5) * tilewidth
        this.centerY = (y + 0.5) * tilewidth

        this.startX = x * tilewidth
        this.startY = y * tilewidth
        this.size = tilewidth

    }


    draw(p5: p5Types, t: number) {

        const scale = p5.map(t, 0, 100, 0, this.maxScale, true)

        p5.fill(this.blue, this.red, this.green)
        if (this.diagonal) {
            p5.fill(this.blue, this.red, this.green)
            p5.arc(this.centerX, this.centerY, this.size * scale, this.size * scale, 225, 45)
            p5.fill(this.red, this.blue, this.green)
            p5.arc(this.centerX, this.centerY, this.size * scale, this.size * scale, 45, 225)

        } else {
            p5.fill(this.blue, this.red, this.green)
            p5.arc(this.centerX, this.centerY, this.size * scale, this.size * scale, -45, 135)
            p5.fill(this.red, this.blue, this.green)
            p5.arc(this.centerX, this.centerY, this.size * scale, this.size * scale, 135, -45)
        }
    }

    drawInitial(p5: p5Types) {
        p5.fill(this.red, this.green, this.blue)
        p5.rect(this.startX, this.startY, this.size, this.size)
        p5.fill(this.red, this.red, this.green)
        if (this.diagonal) {
            p5.push()
            p5.translate(this.centerX, this.centerY)
            p5.triangle(-this.size / 2, -this.size / 2, this.size / 2, -this.size / 2, this.size / 2, this.size / 2)
            p5.translate(-this.centerX, -this.centerY)
            p5.pop()
        } else {
            p5.push()
            p5.translate(this.centerX, this.centerY)
            p5.triangle(-this.size / 2, this.size / 2, this.size / 2, this.size / 2, this.size / 2, -this.size / 2)
            p5.translate(-this.centerX, -this.centerY)
            p5.pop()
        }
    }
}