import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useColorMode } from '@chakra-ui/react'
import { MyCompletedSketches } from '../App';
import { Colormode } from '../components/Colormode';
import styles from './navbar.module.css';

export const GalleryNavbar = () => {
    const { colorMode } = useColorMode()

    return <>
        <nav className={styles.navbar}>
            <div className={styles.navbarContent}>
                <ul className={`${styles.links} ${colorMode === 'light' ? styles.light : styles.dark}`}>
                    <li><Link to={"/"}>Start</Link></li>
                    {MyCompletedSketches.map(it => <li className={styles.hiddenMobile}><Link to={`/gallery/${it.route}`}>{it.name}</Link></li>)}
                    <li><Colormode /></li>
                </ul>
            </div>
        </nav>
        <Outlet />
    </>
}