import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { GeneralSketchProps } from '../types';

export function ParametricSketch({ height, width }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {

    let pFuntions: ParametricFunction[] = []

    const setup = (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(width, height).parent(canvasParentRef);
        p5.background('#1A202C')


        const points = [p5.int(p5.random(1, 10)), p5.int(p5.random(1, 200))]

        let [p1, p2, p3, p4] = getSubset(p5, points)

        pFuntions.push(
            new ParametricFunction(
                p5,
                p1,
                p2,
                p3,
                p4,
                p5.int(p5.random(1, 10)),
                p5.int(p5.random(1, 10)),
                p5.random(255),
                p5.random(255),
                p5.random(255),
                p5.random(100, 255)
            ))


    };


    const scale = 100

    let tick = 1

    const draw = (p5: p5Types) => {

        pFuntions.forEach(f => f.draw(p5, scale, width / 2, height / 2, tick))
        tick++

    };
    return <Sketch setup={setup} draw={draw} />
}

const getSubset = (p5: p5Types, points: number[]): number[] => {

    const res = [p5.random(points), p5.random(points), p5.random(points), p5.random(points)]

    if (res[0] === res[1]) {
        return getSubset(p5, points)
    }

    return res

}

class ParametricFunction {

    r: number;
    g: number;
    b: number;
    alpha: number;


    currentTick: number;
    points: p5Types.Vector[] = []
    constructor(p5: p5Types, a: number, b: number, c: number, d: number, k: number, j: number, r: number, g: number, blue: number, alpha: number) {

        this.r = r;
        this.g = g;
        this.b = blue;
        this.alpha = alpha;

        for (let t = 0; t < 50000; t++) {
            this.points.push(f1(p5, a, b, c, d, k, j, t))
        }
        this.currentTick = 0

    }


    draw(p5: p5Types, scale: number, centerX: number, centerY: number, tick: number) {
        p5.stroke(this.r, this.g, this.b, this.alpha)
        const endIndex = tick * 250
        if (endIndex < this.points.length) {
            this.points.slice(this.currentTick, endIndex).forEach(v => {
                p5.circle(centerX + scale * v.x, centerY + scale * v.y, 1)
            })
            this.currentTick = endIndex
        }

    }

}

const f1 = (p5: p5Types, a: number, b: number, c: number, d: number, k: number, j: number, t: number): p5Types.Vector =>
    p5.createVector(
        p5.cos(a * t) - p5.pow(p5.cos(b * t), k),
        p5.sin(c * t) - p5.pow(p5.sin(d * t), j)
    )

