import React from 'react';
import Sketch from 'react-p5';
import p5Types from "p5";
import { GeneralSketchProps } from '../types';

export function Waves({ height, width }: GeneralSketchProps): React.ReactElement<GeneralSketchProps> {

    let circles: MyCircle[] = []
    const setup = (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(width, height).parent(canvasParentRef);
        p5.angleMode(p5.DEGREES);

        for (let i = 0; i < 50; i++) {
            circles.push(MyCircle.from(p5, p5.createVector(width / 2, height / 2), 200 - 4 * i, i))
        }

        p5.background('#1A202C')
    };

    let t = 0;

    const draw = (p5: p5Types) => {
        p5.background(0)
        p5.noFill()
        p5.stroke(255, 255, 255)
        circles.forEach(g => g.draw(p5, t % 360))
        t++;
    };
    return <Sketch setup={setup} draw={draw} />
}

class MyCircle {
    center: p5Types.Vector;
    r: number;
    g: number;
    b: number;
    radius: number;
    index: number;

    constructor(p5: p5Types, center: p5Types.Vector, radius: number, index: number, r: number, g: number, b: number) {
        this.center = center;
        this.r = r;
        this.g = g;
        this.b = b;
        this.radius = radius;
        this.index = index;
    }

    static from(p5: p5Types, center: p5Types.Vector, radius: number, index: number): MyCircle {
        return new MyCircle(p5, center, radius, index, p5.random(255), p5.random(255), p5.random(255))
    }

    draw(p5: p5Types, delta: number) {

        const currentScale = 1 - p5.cos(delta) * 0.3

        p5.push()
        p5.translate(this.center.x, this.center.y)
        p5.scale(currentScale)
        p5.rotate((90 + this.index * 10) * p5.cos(delta))
        p5.triangle(-this.radius / 2, -this.radius, this.radius, this.radius / 2, this.radius / 2, this.radius / 2)

        p5.pop()
    }
}