import React, { useContext, useState } from 'react';
import { Button, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, useDisclosure } from '@chakra-ui/react'
import { FaChevronLeft, FaChevronRight, FaInfo } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { fallbackFallback, fallbackText } from '../api';
import { MyCompletedSketches } from '../App';
import { TextContext } from '../TextProvider';
import styles from './sidebar.module.css';

interface Props {
    name: string;
}

export const Sidebar: React.FC<Props> = ({ name }) => {

    const text = useContext(TextContext)
    const sketchInfo =
    text.sketches.find(t => t.name === name) ?? fallbackText.find(t => t.name === name) ?? fallbackFallback

    
    return <div className={styles.container}>
         <Stack className={styles.hiddenMobile} justify='center' direction='row'>
                <h2 className={styles.title}>{name}</h2>
        </Stack>
        <p className={styles.description}>{sketchInfo.description}</p>
        <div className={styles.hiddenMobile}>
             <SketchNavigation name={name}/>
        </div>
    </div>
}

export const SketchNavigation = ({name}: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const index = MyCompletedSketches.map(s => s.name).indexOf(name)

    const next = MyCompletedSketches?.[index + 1]
    const previous = MyCompletedSketches?.[index - 1]

    const text = useContext(TextContext)
    const sketchInfo =
    text.sketches.find(t => t.name === name) ?? fallbackText.find(t => t.name === name) ?? fallbackFallback


    let justifyClassname;
    if(previous && next) {
        justifyClassname = 'space-between'
    } else if(previous) {
        justifyClassname = 'flex-start'
    } else {
        justifyClassname = 'flex-end'
    }

    return <div className={styles.navigation}>
           <Modal onClose={onClose} isOpen={isOpen}  >
           <ModalOverlay />
        <ModalContent>
          <ModalHeader>{sketchInfo.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>{sketchInfo.description}</p>
          </ModalBody>
        </ModalContent>
    </Modal>
            <Stack className={styles.hiddenDesktop} justify='center' direction='row'>
                <Stack justify="center" direction="row">
                <h2 className={styles.title}>{name}</h2>
                <IconButton size="sm" onClick={onOpen} aria-label='more-information' icon={<FaInfo/>}/>
                </Stack>
            </Stack>
            <Stack className={styles.navButtons} justify={justifyClassname} direction='row'>
            {previous &&
                <Button leftIcon={<FaChevronLeft />} variant='outline' colorScheme='teal' as={Link} to={`/gallery/${previous.route}`}>{previous.name}</Button>
            }
            {next &&
                <Button rightIcon={<FaChevronRight />} variant='outline' colorScheme='teal' as={Link} to={`/gallery/${next.route}`}>{next.name}</Button>
            }
            </Stack>
        </div>
        
}